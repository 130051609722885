import { inject, Component, Input, computed } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "no-search-results",
   templateUrl: "./noSearchResults.element.component.html",
   standalone: true,
})
export class NoSearchResults {
   @Input() public modalInstance;
   @Input() public resolve;
   @Input() public msg;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
