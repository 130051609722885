<div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
   <img src="assets/img/notFoundRobot.png" />
   <br />
   <br />
   <h3>
      {{ lang().WhoopsDrawingABlank }}
   </h3>
   <h5 style="color: #808080">
      @if (msg) {
         {{ msg }}
      } @else {
         {{ lang().TryAdjustingYourSearchOrFilters }}
      }
   </h5>
</div>
